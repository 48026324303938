// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  height: 100%;
}

#root {
  height: 100%;
  font-size: 12px;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo {
  display: none;
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;;EAEE,aAAa;EACb,UAAU;AACZ","sourcesContent":["html,\nbody {\n  height: 100%;\n}\n\n#root {\n  height: 100%;\n  font-size: 12px;\n}\n\n.mapboxgl-ctrl-attrib,\n.mapboxgl-ctrl-logo {\n  display: none;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
