import React from "react";
import ReactDOM from "react-dom";
import { LicenseInfo } from "@mui/x-license-pro";

import { initBackend } from "@discoverr/web/backend";

import "./index.css";

import { Root } from "./Root";

LicenseInfo.setLicenseKey(
  "6e9ddeb2fb16eb1c66df54d530942283Tz03NTg0NyxFPTE3Mjc5NDEwNDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

initBackend({
  apiHostname: process.env["REACT_APP_API"] as string,
});

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
