import React from "react";

import { Router, Routes, Route, Loadable } from "@discoverr/web/Router";
import { Bootstrap } from "@discoverr/web/Bootstrap";
import { SpaceAuthLoginPage } from "@discoverr/web/SpaceAuth";
import { useNavigate } from "@discoverr/web/Router";
import da from "@discoverr/web/languages/da";
import daDate from "date-fns/locale/da";
// import { daDK } from "@mui/x-date-pickers/locales/daDK";

const loginView = require("./assets/login-image.jpg");

// Constants
const artConfig = { primary: "#eebb55" };

// Views
const LoadableApp = Loadable(React.lazy(() => import("./views/App")));

// Components
const Login = () => {
  const navigate = useNavigate();
  const handleLoginSuccess = () => {
    navigate("/");
  };

  console.log(loginView);

  return (
    <SpaceAuthLoginPage
      title="Eagleeyes"
      subtitle="Log på den interne platform"
      image={loginView}
      onSuccess={handleLoginSuccess}
    />
  );
};

//
export const Root = () => {
  return (
    <Bootstrap
      titleTemplate="Eagleeyes - %s"
      language={da}
      dateFnsLocale={daDate}
      art
      artConfig={artConfig}
    >
      <Router>
        <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/*" element={<LoadableApp />} />
        </Routes>
      </Router>
    </Bootstrap>
  );
};
